ion-modal {
  &.choose-portion-modal {
    &::part(content) {
      height: 40%;
      position: absolute;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;

      @include small-mobile {
        height: 46%;
      }
    }
  }

  &.choose-portion-modal-large {
    &::part(content) {
      height: 49%;
      position: absolute;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
    }
  }

  &.confirmation-modal {
    &::part(content) {
      position: absolute;
      bottom: 0;
      height: 450px;
      border-radius: 20px 20px 0px 0px;
    }
  }

  &.dish-addition-modal {
    &::part(content) {
      height: 95%;
      position: absolute;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
    }
  }

  &.cancel-modal {
    &::part(content) {
      height: 25%;
      position: absolute;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
    }
  }

  &.change-day-modal {
    &::part(content) {
      height: 640px;
      position: absolute;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
    }
  }

  &.payment-split-modal {
    &::part(content) {
      height: 502px;
      position: absolute;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
    }
  }

  &.dish-details-modal,
  &.dish-feedback-modal {
    &::part(content) {
      height: 85vh;
      position: absolute;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
    }
  }

  &.feedback-modal {
    &::part(content) {
      height: 680px;
      max-height: 100vh;
      border-radius: 20px 20px 0px 0px;
    }
  }
}
