.app-tooltip {
  position: absolute;
  max-width: 200px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #000;
  padding: 12px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.35);
  z-index: 1002;
  opacity: 0;
  transition: opacity 1s;
}

.app-tooltip .app-tooltip-pointer {
  content: '';
  position: absolute;
  border-style: solid;
}

.app-tooltip-top .app-tooltip-pointer {
  top: 100%;
  left: 50%;
  margin-left: -0.5rem;
  border-width: 0.5rem;
  border-color: #fff transparent transparent transparent;
}

.app-tooltip-bottom .app-tooltip-pointer {
  bottom: 100%;
  left: 50%;
  margin-left: -0.5rem;
  border-width: 0.5rem;
  border-color: transparent transparent #fff transparent;
}

.app-tooltip-left .app-tooltip-pointer {
  top: 50%;
  left: 100%;
  margin-top: -0.5rem;
  border-width: 0.5rem;
  border-color: transparent transparent transparent #fff;
}

.app-tooltip-right .app-tooltip-pointer {
  top: 50%;
  right: 100%;
  margin-top: -0.5rem;
  border-width: 0.5rem;
  border-color: transparent #fff transparent transparent;
}

.app-tooltip-show {
  opacity: 1;
}
