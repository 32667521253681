body {
  color: var(--ion-text-color);
  font-family: var(--ion-font-family);
  font-size: var(--ion-text-size);
  background-color: var(--ion-background-color);
}

::selection {
  background: #000;
  color: #fff;
}

.ion-page {
  max-width: var(--containerWidthBigMobile);
  margin: 0 auto;
}

.ios iframe {
  padding-top: 59px;
  background-color: #fff;
}
